'use strict';

/* internationalization helper methods */
void function(i18n) {
  window.i18n = i18n

  const priceFormatters = {}

  i18n.formatDistance = function(value, shortAsMetres = false) {
    let unit

    if (i18n.usesMetric) {
      if (value < 1 && shortAsMetres) {
        value *= 1000
        unit = 'm'
      }
      else
        unit = 'km'
    }
    else {
      value = (value * 0.6213).toFixed(1)
      unit = 'miles' // value is a string with decimal precision, so 1 mile is returned as 1.0 and plural applies.
    }

    return `${value}${unit}`
  }

  i18n.formatPrice = function(value, currency, maxDigits = 2, canShrink = false) {
    if (canShrink && Number.isInteger(value))
      maxDigits = 0

    const lang = document.documentElement.getAttribute('lang')
    const key = currency + lang + maxDigits

    if (!priceFormatters[key]) {
      const opts = {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
        maximumFractionDigits: maxDigits,
      }

      priceFormatters[key] = new Intl.NumberFormat(lang, opts)
    }

    return priceFormatters[key].format(value)
  }
}(window.i18n || {})
